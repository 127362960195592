import React, { useLayoutEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GridLayout, { GridItem } from '../components/utils/GridLayout'
import Cta from '../components/utils/Cta'

import style from './style/faq.module.scss'

import { plants } from '../plants/faq-plants'
import { MovingPlant } from './index'

/**
 * Open / close answer
 * @param  {boolean} options.open    	state
 * @param  {function} options.setOpen state modifier]
 * @return {function} onclick event handler
 */

function toggleFaq({open,setOpen}, animation) {
	return (evt) => {
		evt.preventDefault();
		setOpen(!open);

    if ( animation ) {
      let domElement = document.querySelector(`[data-aos=${animation}]`);

      if ( domElement && !domElement.classList.contains('aos-manual') )
        domElement.classList.add('aos-manual')
    }
	}
}

/**
 * Set open question height dynamically
 * @param {[type]} options.domContent [description]
 * @param {[type]} options.wrapper    [description]
 */
function setFaqHeight({domContent, wrapper}) {
	const { current } = domContent;
	const { height } = current ? current.getBoundingClientRect() : { height: 0};

  if ( wrapper.current )
    wrapper.current.style.height = `${height}px`;
}

function Faq({title, content, animation}) {
	const [open, setOpen] = useState(false);
	
	// Refs
	const wrapper = React.createRef();
	const domContent = React.createRef();

	let className = '';

	if ( open ) className += ` ${style.faqIsOpen}`;

	useLayoutEffect(() => {

		const resizeHandler = () => {
			if ( open ) {
				setFaqHeight({wrapper,domContent});
			}
		}

		if ( open ) {
			setFaqHeight({wrapper,domContent});
		} else {
			wrapper.current.style.height = `${0}px`;
		}

		window.addEventListener('resize', resizeHandler);
		return () => {
			window.removeEventListener('resize', resizeHandler);
		}

	},[open]);

	return(
		<div className = { `${style.faq}${className}` }>

			<div className = { style.faq__title }>
				<h2>{ title }</h2>

				<a
					onClick = { toggleFaq({open, setOpen}, animation) }
					className = { style.faq__handle }
					role = 'button'>
					<span></span>
					<span></span>
				</a>

			</div>

			<div 
				ref = { wrapper }
				className = { style.faq__wrapper }>
				<div 
					ref = { domContent }
					className = { style.faq__content }>
					<div 
						className = 'crps'
						dangerouslySetInnerHTML = {{ __html: content }}></div>
				</div>
			</div>
		</div>
	)
}


const FaqPage = () => {
  const { allFaqYaml } = useStaticQuery(graphql`
    {
      allFaqYaml {
        nodes {
        	id
          title
          content
          animation
        }
      }
    }
  `)

  const faqs = allFaqYaml.nodes;

  return (
    <Layout>
      <SEO title="Q&A" />
      
      <div id = { style.FaqPage }>

        { plants.map((plant,key) => (
          <MovingPlant
            className = { style.MovingPlant }
            key = { key }
            { ...plant }/>
        )) }

      	<div 
      		className = { style.Leaf }
      		id = { style.RedLeaf } />
      	<div 
      		className = { style.Leaf }
      		id = { style.BlueLeaf } />

      	<GridLayout>
      		<GridItem grid = {{
      			push: {lg: 2, md: 2},
      			col: {lg: 8, md: 8},
      		}}>
            <header>
        			<h1 className = 'title title--pages'>
        				Questions & Answers
        			</h1>
            </header>
      		</GridItem>
      	</GridLayout>

      	<div id = { style.Questions }>

      		<GridLayout>
      			<GridItem grid = {{
      				push: {lg: 1, md: 1},
      				col: {lg: 10, md: 10},
      			}}>
      				{ faqs.map(({title, content, id, animation}) => (
      					<Faq 
      						key = { id }
      						title = { title } 
      						content = { content }
                  animation = { animation }/>
      				)) }
      			</GridItem>
      		</GridLayout>

      	</div>

      	<div className = { `wrapper ${style.cta}` }>
      		<Cta
      			href = '/press-kit'
      			className = 'button button--blue'>
      			Download our press kit
      		</Cta>
      	</div>

      </div>

    </Layout>
  )
}

export default FaqPage

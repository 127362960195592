import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Loadable from "react-loadable"


import Layout from "../components/layout"
import SEO from "../components/seo"
import GridLayout, { GridItem } from '../components/utils/GridLayout'
import Cta from '../components/utils/Cta'

import Animation from '../components/utils/Animation'

import { pageContent, removeNewLine } from '../functions'

import style from './style/index.module.scss'

/**
 * Images
 */

// import PlantBasedScrambleEggs from '../components/images/PlantBasedScrambleEggs'
// import FoodNextGeneration from '../components/images/FoodNextGeneration'
import FoodNextGeneration from '../images/services/food-brand.svg'
import Pizza from '../components/images/Pizza'
import MicroAlgae from '../components/images/MicroAlgae'
import Scientist from '../components/images/Scientist'
import AlgaeCover from '../components/images/AlgaeCover'

import algae from '../images/home/algae/algae.svg';
import muscle from '../images/home/algae/muscle.svg';
import earth from '../images/home/algae/earth.svg';

import share from '../images/home/mission/share.svg';
import pioneer from '../images/home/mission/pioneer.svg';
import democratize from '../images/home/mission/democratize.svg';

import seed01 from '../images/home/seeds/seed-01.svg';
import seed02 from '../images/home/seeds/seed-02.svg';
import seed03 from '../images/home/seeds/seed-03.svg';
import seed04 from '../images/home/seeds/seed-04.svg';
import seed05 from '../images/home/seeds/seed-05.svg';


/**
 * Plants
 */
import { 
  plants, 
  paralaxPlants, 
  paralaxPlants02,
  paralaxPlants03,
  paralaxPlants04,
} from '../plants/plants'



/**
 * Animations
 */
import sustainableAnimation from '../animations/sustainable.json'
import plantBasedAnimation from '../animations/plant-based.json'
import microAlgaeAnimation from '../animations/algae.json'
import plantAnimation from '../animations/plante.json'
import foodAnimation from '../animations/food.json'

/**
 * Get title from parsed .md file
 * @param  {Object} page [description]
 * @return {String}      [description]
 */
export function getTitleValue(page,i) {
  if ( !i ) i = 0;
  return page.children[i].value;
}

/**
 * Get content block from parsed .md file
 * @param  {Object} page [description]
 * @return {String}      [description]
 */
export function getContentValue(page) {
  return page.children.map(pageContent);
}

/**
 * Get nested data from parsed .md file
 * @param  {Object} page [description]
 * @param  {Number} n    [description]
 * @return {String}      [description]
 */
function getStatement(page,n) {
  return page.children.filter(removeNewLine)[n].children.map(pageContent);
}

/**
 * Statement
 * @param {[type]} options.content [description]
 * @param {[type]} options.src     [description]
 */
function AlgaeStatement({content,src, animation}) {
  return (
    <div className = { style.algaeStatement }>
      <div className = { style.algaeStatement__img }>
        { animation ? 
          <Animation
            id = { animation.id }
            className = { style.Animation }
            animationData = { animation.animationData } />
          : <img src = { src } alt = { content[0] } /> }
        
      </div>
      <p 
        className = { 'crps title--white ' + style.algaeStatement__content }>
        { content }
      </p>
    </div>
  )
}


/**
 * Animated plants - Animate on scroll
 * @param {String} options.id        [description]
 * @param {String} options.className [description]
 * @param {String} options.aos       [description]
 * @param {String} options.src       [description]
 * @param {String} options.alt       [description]
 */
export function MovingPlant({ 
  id, className, aos, src, alt, imgClassName 
}) {
  return (
    <div
      id = { id } 
      className = { className }>
      <img
        className = { imgClassName }
        data-aos-once
        data-aos = { aos } 
        src = { src }
        alt = { alt } />
    </div>
  );
}


/**
 * Lazy loader
 */
const loader=()=>(<div>Loading...</div>)

const ParalaxPlant = Loadable({
  loader: () => import("../components/utils/ParalaxPlant"),
  loading: loader,
});

const ParallaxProvider = Loadable({
  loader: () => import("../components/utils/Parallax"),
  loading: loader,
});


const IndexPage = () => {
  const { allMarkdownRemark: {edges} } = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(index)/"}}) {
        edges {
          node {
            htmlAst
            fileAbsolutePath
            headings {
              depth
              value
            }
          }
        }
      }
    }
  `)

  const { node: { htmlAst: { children } } } = edges[0];
  const pageData = children.filter(removeNewLine);

  const algaeStatements = [{
    src: algae,
    content: getStatement(pageData[10], 0),
    animation: {
      animationData: plantAnimation,
      id: style.AnimationPlant,
    },
  },{
    src: muscle,
    content: getStatement(pageData[10], 1),
  },{
    src: earth,
    content: getStatement(pageData[10], 2),
  },{
    src: share,
    content: getStatement(pageData[18], 0),
  },{
    src: democratize,
    content: getStatement(pageData[18], 1),
  },{
    src: pioneer,
    content: getStatement(pageData[18], 2),
  },];

  return (
    <Layout>
      <SEO title="Home" />
      
      <div id = { style.Header }>

        {plants.map((plant,key) => (
          <MovingPlant
            className = { style.MovingPlant }
            key = { key }
            { ...plant }/>
        ))}

        <div 
          id = { style.MobilePlant02 }
          className = { style.MobilePlant } />

        <div 
          id = { style.MobilePlant03 }
          className = { style.MobilePlant } />

        <GridLayout>
          <GridItem grid = {{
            col: { md: 8, lg: 8 },
            push: { md: 2, lg: 2 }
          }}>
            <h1 className = 'title title--h1'>
              { getTitleValue(pageData[0]) }<span>{ getTitleValue(pageData[0],2) }</span>
            </h1>
            <h2 className = 'title title--h2'>
              { getTitleValue(pageData[1]) }
            </h2>
            <p className = 'crps'>
              { getContentValue(pageData[2]) }
            </p>

            <Cta 
              href = '/contact'
              className = 'button button--contact'>
              Contact us
            </Cta>

          </GridItem>
        </GridLayout>

        <div data-aos = 'dry-appearance' className = { style.AnimationWrapper + ' wrapper' }>
          <Animation
            id = { style.AnimationSustainable }
            className = { style.Animation }
            height = { 162 }
            width = { 162 }
            animationData = { sustainableAnimation } />

          <Animation
            id = { style.AnimationPlantBased }
            className = { style.Animation }
            height = { 112 }
            width = { 112 }
            animationData = { plantBasedAnimation } />
        </div>

        <div className = { `wrapper ${style.SeedsWrapper}`}>
          <div className = { `${style.Seeds} Seeds` }>
            <img data-aos src = { seed01 } alt = 'algae' />
            <img data-aos src = { seed02 } alt = 'algae' />
            <img data-aos src = { seed03 } alt = 'algae' />
            <img data-aos src = { seed04 } alt = 'algae' />
            <img data-aos src = { seed05 } alt = 'algae' />
          </div>
        </div>

      </div>

      <div id = { style.Section01 }>
        <div className = { style.section }>
          <GridLayout className = 'grid-flex--center'>
            <GridItem 
              className = 'grid-flex__item'
              grid = {{ col: { md: 7, lg: 7 } }}>
              <div className = { style.section__img }>
                <Animation
                  className = { style.ScrambledEggs }
                  animationData = { foodAnimation } />
                {/*<PlantBasedScrambleEggs 
                  className = { style.ScrambledEggs }
                  alt = { getTitleValue(pageData[3]) } />*/}
              </div> 
            </GridItem>

            <GridItem 
              className = 'grid-flex__item'
              grid = {{ col: { md: 5, lg: 5 } }}>
              
              <h2 className = { 'title title--h3 ' + style.section__title }>
                { getTitleValue(pageData[3]) }
              </h2>

              <h3 className = { 'title title--h4 ' + style.section__sub }>
                { getTitleValue(pageData[4]) }
              </h3>

              <p className = { 'crps ' + style.section__content }>
                { getContentValue(pageData[5]) }
              </p>

              <Cta 
                href = '/services'
                className = 'button button--blue'>
                Need an ingredient?
              </Cta>

            </GridItem>
          </GridLayout>
        </div>


        <div className = { style.section }>
          <GridLayout className = 'grid-flex--center grid-flex--reverse-md'>
            <GridItem 
              className = 'grid-flex__item'
              grid = {{ col: { md: 6, lg: 6 } }}>
              <div className = { style.section__img }>
                <div className = { style.FoodNextGen }>
                  <img 
                    src = { FoodNextGeneration }
                    alt = { getTitleValue(pageData[6]) }/>
                </div>
              </div> 
            </GridItem>

            <GridItem 
              className = 'grid-flex__item'
              grid = {{ col: { md: 6, lg: 6 } }}>
              
              <h2 className = { 'title title--h3 ' + style.section__title }>
                { getTitleValue(pageData[6]) }
              </h2>

              <h3 className = { 'title title--h4 ' + style.section__sub }>
                { getTitleValue(pageData[7]) }
              </h3>

              <p className = { 'crps ' + style.section__content }>
                { getContentValue(pageData[8]) }
              </p>

              <Cta 
                href = '/services'
                className = 'button button--blue'>
                Need a product?
              </Cta>

            </GridItem>
          </GridLayout>
        </div>

      </div>

      <ParallaxProvider>
        <div id = { style.MicroAlgae }>

          { paralaxPlants.map((plant, key) => (
            <ParalaxPlant
              key = { key }
              className = { style.MovingPlant } 
              { ...plant } />
          )) }

          <div className = 'wrapper'>
            <h2 className = 'title title--h3 title--white'>
              { getTitleValue(pageData[17]) }
            </h2>
          </div>
          <GridLayout>
            { algaeStatements.slice(3).map((statement, key) => (
              <GridItem key = { key } grid = {{
                col: { md: 4, lg: 4 }
              }}>
                <AlgaeStatement { ...statement } />
              </GridItem>
            )) }
          </GridLayout>

          <div className = { `wrapper ${style.missionCta}` }>
            <Cta 
              extern = { true }
              href = '/manifesto'
              className = 'button button--white'>
              Our vision
            </Cta>
          </div>
          
        </div>
      </ParallaxProvider>

      <div id = { style.Section02 }>

        <div 
          id = { style.MobilePlant04 }
          className = { style.MobilePlant } />

        <div className = { style.section }>
          <GridLayout className = 'grid-flex--center grid-flex--reverse-md'>
            <GridItem 
              className = 'grid-flex__item'
              grid = {{ col: { md: 6, lg: 6 } }}>
              <div className = { style.section__img }>
                <Pizza 
                  className = { style.Pizza }
                  alt = { getTitleValue(pageData[11]) } />
              </div> 
            </GridItem>

            <GridItem 
              className = 'grid-flex__item'
              grid = {{ 
                col: { md: 5, lg: 5 },
                pull: { md: 1, lg: 1 }
              }}>
              
              <h2 className = { 'title title--h3 ' + style.section__title }>
                { getTitleValue(pageData[11]) }
              </h2>

              <h3 className = { 'title title--h4 ' + style.section__sub }>
                { getTitleValue(pageData[12]) }
              </h3>

              <p className = { 'crps ' + style.section__content }>
                { getContentValue(pageData[13]) }
              </p>

              <Cta 
                href = '/manifesto'
                className = 'button button--blue'>
                Our vision
              </Cta>

            </GridItem>
          </GridLayout>
        </div>


        <div className = { style.section }>
          <GridLayout className = 'grid-flex--center'>
            <GridItem 
              className = 'grid-flex__item'
              grid = {{ col: { md: 5, lg: 5 } }}>
              <div className = { style.section__img }>
                <MicroAlgae 
                  className = { style.MicroAlgaeImg }
                  alt = { getTitleValue(pageData[14]) }/>
              </div> 
            </GridItem>

            <GridItem 
              className = 'grid-flex__item'
              grid = {{ 
                col: { md: 6, lg: 6 },
                push: { md: 1, lg: 1 }
              }}>
              
              <h2 className = { 'title title--h3 ' + style.section__title }>
                { getTitleValue(pageData[14]) }
              </h2>

              <h3 className = { 'title title--h4 ' + style.section__sub }>
                { getTitleValue(pageData[15]) }
              </h3>

              <p className = { 'crps ' + style.section__content }>
                { getContentValue(pageData[16]) }
              </p>

              <Cta 
                href = '/services'
                className = 'button button--blue'>
                Our services
              </Cta>

            </GridItem>
          </GridLayout>
        </div>

      </div>

      <ParallaxProvider>
        <div id = { style.Mission }>

          <div 
            id = { style.MobilePlant05 }
            className = { style.MobilePlant } />
          <div 
          id = { style.MobilePlant06 }
          className = { style.MobilePlant } />

          { paralaxPlants02.map((plant, key) => (
            <ParalaxPlant
              key = { key }
              className = { style.MovingPlant } 
              { ...plant } />
          )) }

          <div className = 'wrapper'>
            <h2 className = 'title title--h3 title--white'>
              { getTitleValue(pageData[9]) }
            </h2>
          </div>
          <GridLayout>
            { algaeStatements.slice(0,3).map((statement, key) => (
              <GridItem key = { key } grid = {{
                col: { md: 4, lg: 4 }
              }}>
                <AlgaeStatement { ...statement } />
              </GridItem>
            )) }
          </GridLayout>

        </div>
      </ParallaxProvider>

      <div id = { style.Section03 }>

        { paralaxPlants04.map((plant, key) => (
          <ParalaxPlant
            key = { key }
            className = { style.MovingPlant } 
            { ...plant } />
        )) }

        <div className = { style.section }>
          <GridLayout className = 'grid-flex--center grid-flex--reverse-md'>
            <GridItem 
              className = 'grid-flex__item'
              grid = {{ col: { md: 6, lg: 6 } }}>
              <div className = { style.section__img }>
                <Scientist 
                  className = { style.Scientist }
                  alt = { getTitleValue(pageData[19]) } />
              </div> 
            </GridItem>

            <GridItem 
              className = 'grid-flex__item'
              grid = {{ 
                col: { md: 5, lg: 5 },
                pull: { md: 1, lg: 1 }
              }}>
              
              <h2 className = { 'title title--h3 ' + style.section__title }>
                { getTitleValue(pageData[19]) }
              </h2>

              <h3 className = { 'title title--h4 ' + style.section__sub }>
                { getTitleValue(pageData[20]) }
              </h3>

              <p className = { 'crps ' + style.section__content }>
                { getContentValue(pageData[21]) }
                <ul>{ pageData[22].children.filter(removeNewLine).map(pageContent) }</ul>
              </p>

              <Cta 
                href = '/services'
                className = 'button button--blue'>
                Our R&D expertise
              </Cta>

            </GridItem>
          </GridLayout>
        </div>
      </div>

      {/* Cover photo */}
      <div className = { style.AlgaeCover }>
        <AlgaeCover
          alt = { getTitleValue(pageData[19]) } />

          <div className = { style.AnimationWrapper + ' wrapper' }>
            <Animation
              id = { style.AnimationMicroAlgae }
              className = { style.Animation }
              height = { 157 }
              width = { 157 }
              animationData = { microAlgaeAnimation } />
          </div>
      </div>

      <ParallaxProvider>
        <div id = { style.PlantBasedAlternative }>

          { paralaxPlants03.map((plant, key) => (
            <ParalaxPlant
              key = { key }
              className = { style.MovingPlant } 
              { ...plant } />
          )) }

          <GridLayout>
            
            <GridItem grid = {{
              col: { md: 8, lg: 6 },
              push: { md: 2, lg: 3 }
            }}>

              <h2 className = 'title title--h4'>
                { getTitleValue(pageData[29]) }
              </h2>

              <Cta 
                href = '/contact'
                className = 'button button--regular'>
                Let's talk
              </Cta>
              
            </GridItem>

          </GridLayout>

        </div>
      </ParallaxProvider>

    </Layout>
  )
}

export default IndexPage
